import React from 'react';
import LoginTitle from '@components/Login/LoginTitle';
import { css } from '@emotion/react';

type OurOffersProps = {
  title: string;
  __html: string;
};

const OurOffers: React.FC<OurOffersProps> = ({ title, __html }) => {
  if (!__html) return null;
  return (
    <div
      className="container"
      css={css`
        padding: 1rem;
        background: var(--color-Alabaster);

        @media (min-width: 768px) {
          padding: 1.5rem;
        }

        .login-message {
          font-size: 1.1em;

          ul {
            list-style: none;
            padding: 0;
            margin: 0;
          }

          li {
            display: flex;
          }

          li::before {
            content: '✓ ';
            font-family: system-ui;
            font-weight: 700;
            color: var(--productPrimary);
            margin-right: 0.5em;
          }

          strong {
            font-weight: 600;
          }
        }
      `}
    >
      <LoginTitle title={[title]} />
      <div className="login-message" dangerouslySetInnerHTML={{ __html }} />
    </div>
  );
};

export default OurOffers;
