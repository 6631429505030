import { css } from '@emotion/react';
import React from 'react';

type LoginTitleProps = {
  /** An array of strings with between 1 and 3 elements. */
  title: [string, string?, string?];
  /** The array index you want to highlight. */
  emphasis?: 0 | 1 | 2;
  /** The html  */
};

/**
 * Takes a title as an array of strings, and takes the index of the string to
 * emphasise in VL's vernacular.
 *
 * @example
 * // Emphasises 'Log' in 'Log in'
 * <LoginTitle title={['Log', 'in']} emphasis={0} />
 *
 * @example
 * // Emphasises nothing
 * <LoginTitle title={['Our offer']}  />
 */
const LoginTitle: React.FC<LoginTitleProps> = ({ title, emphasis = null }) => (
  <h1
    css={css`
      font-weight: 700;
      font-size: 2.2em;
      line-height: 1;
      color: var(--productGray);
      margin-bottom: 0.8em;

      span {
        color: var(--productPrimary);
      }
    `}
  >
    {title.map((part, i) => (i === emphasis ? <span key={i}>{part} </span> : `${part} `))}
  </h1>
);

export default LoginTitle;
