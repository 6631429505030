import { ReactNode, CSSProperties } from 'react';
import styled from '@emotion/styled';

type X = 'left' | 'center' | 'right';
type Y = 'top' | 'center' | 'bottom';

type AlignProps = {
  /** The content to align. */
  children: ReactNode;
  /** The vertical alignment of the content. */
  x?: X;
  /** The horizontal alignment of the content. */
  y?: Y;
};

const justifyContentProperties = new Map<X, CSSProperties['justifyContent']>([
  ['left', 'flex-start'],
  ['center', 'center'],
  ['right', 'flex-end']
]);

const alignItemsProperties = new Map<Y, CSSProperties['alignItems']>([
  ['top', 'flex-start'],
  ['center', 'center'],
  ['bottom', 'flex-end']
]);

const Align = styled.div<AlignProps>(
  { display: 'flex' },
  ({ x = 'left' }) => ({ justifyContent: justifyContentProperties.get(x) }),
  ({ y = 'top' }) => ({ alignItems: alignItemsProperties.get(y) })
);

export default Align;
