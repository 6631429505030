import React from 'react';
import OurOffers from '@components/Login/OurOffers';
import Header from '@components/Layouts/Header/Header';
import useArticle from '@hooks/useArticle';
import { css } from '@emotion/react';
import useRuntimeConfig from '@vl-core/useRuntimeConfig';
import { Alert } from 'ALIASED-antd';

const LoginLayout: React.FC<{
  children: React.ReactNode;
  title: string;
  showSidebar?: boolean;
  sidebarArticle?: string;
}> = ({ children, title, showSidebar = true, sidebarArticle = 'services' }) => {
  const article = useArticle(sidebarArticle, true);
  const { LUCY_ENV, PROD_PATIENT_URL } = useRuntimeConfig();
  const isProd = LUCY_ENV.includes('prod');

  return (
    <div
      css={css`
        .login-header {
          border-bottom: 1px solid var(--productIron);
        }

        section {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          min-height: calc(100vh - 200px);
        }

        .login-card {
          display: grid;
          margin: 0 auto;
          max-width: 768px;
          width: 100%;
          border-radius: 5px;
          border: 1px solid var(--productIron);
          overflow: hidden;
        }

        .login-left {
          padding: 1rem;
        }

        @media (min-width: 768px) {
          .login-card {
            grid-template-columns: 7fr 5fr;
          }
          .login-left {
            padding: 1.5rem;
          }
        }

        .login-layout {
          padding: 1em;

          .ant-form-item-required {
            font-size: 16px;
          }

          .ant-form-item-extra,
          .ant-form-item-explain {
            margin-top: 5px;
          }

          .ant-input-affix-wrapper {
            padding: 0 11px;
          }

          .ant-input {
            height: 3em;
            font-size: 16px;
          }

          .login-alert {
            margin: 0 0 20px;
          }

          /* Add padding-bottom only for mobile views */
          @media (max-width: 767px) {
            padding-bottom: 150px;
          }
        }
      `}
    >
      <Header pageType="login" className="login-header" pageTitle={title} />

      <section className="login-layout">
        {!isProd && PROD_PATIENT_URL && (
          <Alert
            type="warning"
            message="This is a test environment"
            description={
              <div>
                You are currently on a test environment. The{' '}
                <a tabIndex={0} href={PROD_PATIENT_URL}>
                  correct website can be found here
                </a>
                .
              </div>
            }
            css={{ marginTop: 20, marginBottom: 20, maxWidth: '768px', width: '100%' }}
            closable
            showIcon
          />
        )}
        <div className="login-card">
          <div className="login-left">{children}</div>

          {article && showSidebar && <OurOffers title={article.title} __html={article.body} />}
        </div>
      </section>
    </div>
  );
};

export default LoginLayout;
