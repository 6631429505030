import { css } from '@emotion/react';
import React from 'react';
import { useRuntimeConfig } from 'vl-common/src/hooks/Runtime';

type HintProps = {
  children?: React.ReactNode;
  paddingTop?: boolean;
  paddingBottom?: boolean;
};

/**
 * Displays a hint with optional padding. If no children are provided, the hint
 * directs users to the contact us page.
 */
const Hint: React.FC<HintProps> = ({ children, paddingTop = false, paddingBottom = false }) => {
  const { CONTACT_US_URL } = useRuntimeConfig();

  return (
    <div
      className="hint"
      css={css`
        padding-top: ${paddingTop ? '1em' : '0'};
        padding-bottom: ${paddingBottom ? '1em' : '0'};
      `}
    >
      {children || (
        <>
          Need help?{' '}
          <a data-testid="contact-us" href={CONTACT_US_URL} target="_blank" rel="noreferrer">
            Contact us
          </a>
        </>
      )}
    </div>
  );
};

export default Hint;
